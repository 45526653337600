export const headerData = {
  name: `Ryan Duffield`,
  title: `Full Stack Developer`
}

export const footerData = {
	email: `ryan at ryanduffield dot com`,
	links: [
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/in/ryanduffield'
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/telefrancais'
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/telefrancais'
		}
	]
}

export const profileData = {
	title: 'PROFILE',
	text: 'I am a resourceful developer with a diverse knowledge of programming languages and systems. My professional technology skills cover a wide variety of design and development experience including web applications, SQL databases and other technologies. My interpersonal abilities involve articulate communication and a demonstrated talent for working effectively with a team.'
}

export const workData = {
	title: 'WORK EXPERIENCE',
	experience: [
		{
			title: 'Full Stack Developer',
			subtitle: '2019 - Present, TELUS Digital',
			responsibilities: [
				'Works on the high velocity Research and Learn team to bring campaigns and pages to fruition in a very short amount of time',
				'Develops components using TELUS web apps and the TELUS Design System to perfectly match a design',
				'Writes code to consume APIs that display product information in product experience pages',
				'Supports the team in Contentful by modifying existing components or building pages as required',
				'Provides maintenance to web apps by writing unit tests and upgrading to newer versions of packages'
			]
		},
		{
			title: 'Software Developer',
			subtitle: '2016 - 2019, Crystallized Games',
			responsibilities: [
				'Was a key developer on a battle-arena style multiplayer video game and the primary developer on a utility mobile app',
				'Learned Unreal Engine in a very short amount of time and was responsible for developing and programming the abilities for the in-game characters, including melee and ranged attacks',
				'Programmed and designed the menu system, including a lobby for players to join and audio and video adjustment settings',
				'Developed the networking system in Javascript and MongoDB, allowing players to host and join games',
				'Fully developed a mobile phone travel app. It was originally built in Swift, but has been updated using React Native'
			]
		},
		{
			title: 'Systems Developer',
			subtitle: '2014 - 2015, Municipal Property Assessment Corporation',
			responsibilities: [
				'Worked on a team to develop the Workplace Management System (WMS), a web application, that is being used internally by hundreds of employees',
				'Manipulated the ServiceNow platform to suit the requirements provided by the business team',
				'Wrote scripts and transform maps to import data into the WMS database',
				'Created business rules and script includes to users to perform certain tasks',
				'Performed maintenance and fixed bugs through performance testing'
			]
		}
	]
}

export const educationData = {
	title: 'EDUCATION',
	experience: [
		{
			title: 'Seneca College',
			subtitle: '2016, Computer Programming and Analysis Coop'
		},
		{
			title: 'York University',
			subtitle: '2010, BA, Honours English and Professional Writing'
		}
	]
}

export const interestData = {
	title: 'INTERESTS',
  text: 'softball, making web apps, finding the best mexican food in every city, video games about dystopian futures, making lists'
}

export const errorData = {
	title: 'PAGE DOES NOT EXIST',
  text: 'You tried to access a page that does not exist.'
}