import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"
import { headerData } from "../../siteData/data"

const Header = () => (
  <header>
    <div className='header-container'>
        <Link to="/" className='invisible-link' style={{ textDecoration: 'none' }}>
          <div className='header-flex'>
            <h2 className='name'>{headerData.name}</h2>
            <h2 className='title'>{headerData.title}</h2>
          </div>
        </Link>
    </div>
  </header>
)

Header.propTypes = {
  fullName: PropTypes.string,
  jobTitle: PropTypes.string
}

Header.defaultProps = {
  fullName: '',
  jobTitle: ''
}

export default Header
