import React from "react"

import "./blurb.scss"

const Blurb = ({ title, text }) => (
	<div className='blurb-container'>
		<h3 className='blurb-title'>{title}</h3>
		<span className='blurb-text'>{text}</span>
	</div>
)

export default Blurb
