import React from "react"

import "./footer.scss"
import { footerData } from "../../siteData/data"

const Footer = () => {
	let defaultWidth
	if (typeof window !== `undefined`) {
		defaultWidth = window.innerWidth
	}

	return (
		<footer>
			<div className='footer-container'>
				<p className='footer-text'>{footerData.email}</p>
				{defaultWidth > 768 && (
					<p className='footer-divider'>|</p>
				)}
				{footerData.links.map((link, i) => {
					if((i === footerData.links.length - 1)) {
						return (
							<a className='footer-link' href={link.link}>{link.name}</a>
						)
					}
						return (
							<div className='link-container'>
								<a className='footer-link' href={link.link}>{link.name}</a>
								{defaultWidth > 768 && (
									<p className='footer-divider'>|</p>
								)}
							</div>
						)
					}
				)}
			</div>
		</footer>
	)
}

export default Footer
